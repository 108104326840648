/**
 * @important In case you have the idea to add models to an object getter, don't. It does not work.
 */
export enum ColName {
  BoardTabs = 'board-tabs',
  AnalyticEvents = 'analytic-events',
  Articles = 'articles',
  Audios = 'audios',
  Boards = 'boards',
  BoardEdges = 'board-edges',
  BoardNodes = 'board-nodes',
  BoardNodeComments = 'board-node-comments',
  Chats = 'chats',
  DeckHistories = 'deck-histories',
  DeckSections = 'deck-sections',
  Decks = 'decks',
  DailyPracticeStats = 'daily-practice-stats',
  DevelopmentTokens = 'development-tokens',
  Dictionary = 'dictionary',
  ExerciseHistories = 'exercise-histories',
  Exercises = 'exercises',
  Feedbacks = 'feedbacks',
  Files = 'files',
  Forums = 'forums',
  ImportJobs = 'import-jobs',
  LegalArtifacts = 'legal-artifacts',
  Logs = 'logs',
  Limits = 'limits',
  Messages = 'messages',
  Notifications = 'notifications',
  Organizations = 'organizations',
  Posts = 'posts',
  Prompts = 'prompts',
  PushSubscriptions = 'push-subscriptions',
  QRCodes = 'qr-codes',
  RefCache = 'ref-cache',
  ReleaseNotes = 'release-notes',
  Roles = 'roles',
  Settings = 'settings',
  ShareLinks = 'share-links',
  Teams = 'teams',
  Tokens = 'tokens',
  Users = 'users',
  UserActivity = 'user-activity',
  Videos = 'videos',
}
