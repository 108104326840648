import { BoardBrowser } from '@components/boards/board-browser'
import { NodeSearch } from '@components/boards/node-search'
import { Feed } from '@components/notifications/feed'
import { Trans } from '@lingui/macro'
import { Tabs } from '@mantine/core'
import { IconActivity, IconLayoutBoard, IconSearch } from '@tabler/icons-react'
import React, { useState } from 'react'

type CommandCenterProps = React.ComponentProps<typeof BoardBrowser>

export function CommandCenter(props: CommandCenterProps) {
  const [tab, setTab] = useState<string>('boards')

  return (
    <Tabs className='font-medium' value={tab} onChange={setTab}>
      <Tabs.List mb={16}>
        <Tabs.Tab
          fz='xs'
          value='boards'
          leftSection={<IconLayoutBoard size={16} />}
        >
          <Trans>Boards</Trans>
        </Tabs.Tab>
        <Tabs.Tab fz='xs' value='search' leftSection={<IconSearch size={16} />}>
          <Trans>Search</Trans>
        </Tabs.Tab>
        <Tabs.Tab fz='xs' value='feed' leftSection={<IconActivity size={16} />}>
          <Trans>Feed</Trans>
        </Tabs.Tab>
      </Tabs.List>

      <Tabs.Panel value='boards'>
        <BoardBrowser {...props} />
      </Tabs.Panel>

      <Tabs.Panel value='search'>
        <NodeSearch />
      </Tabs.Panel>

      <Tabs.Panel value='feed'>
        <Feed />
      </Tabs.Panel>
    </Tabs>
  )
}
