import {
  ClientBoardCollection,
  ClientBoardNodeCommentCollection,
} from '@/client/data'
import { normalize } from '@/client/utils/text'
import { BoardWorkspace } from '@/common/constants/boards'
import { useData } from '@hooks/use-data'
import { useMetaboardAuth } from '@hooks/use-metaboard-auth'
import { ObjectId } from 'bson'
import { useState } from 'react'

function getFilter(currentWorkspace: string, userId: string) {
  if (ObjectId.isValid(currentWorkspace)) {
    return { org: currentWorkspace }
  }

  if (currentWorkspace === BoardWorkspace.Personal) {
    return {
      $or: [
        { org: { $exists: false } },
        {
          // If a board belongs to an organization it can still be shared to normal users
          // This is in case the user is seeing his personal scope, otherwise intra-org/team permissions will apply
          shareWith: userId,
        },
      ],
    }
  }

  return {}
}

export function useBoardSearch(selector = {}) {
  const { userId, currentWorkspace } = useMetaboardAuth()

  const filter = getFilter(currentWorkspace, userId)

  const boards = useData({
    method: 'boards.list',
    collection: ClientBoardCollection,
    filter: { ...filter, ...selector },
    sort: { name: 1, createdAt: 1 },
    authenticated: true,
    selectiveSync: true,
  })

  useData({
    method: 'board.node.comment.list',
    collection: ClientBoardNodeCommentCollection,
    authenticated: true,
    selectiveSync: true,
  })

  const [search, setSearch] = useState('')

  const normalized =
    boards.data?.map(board => ({
      ...board,
      _name: normalize(board.name).toLowerCase(),
    })) ?? []

  const filteredBoards = normalized.filter(
    (board: { _name: string | string[] }) =>
      board._name.includes(search.toLowerCase()),
  )

  return {
    loading: boards.loading,
    search,
    boards: filteredBoards,
    setSearch,
  }
}
