import { ColName } from '@/common/constants/col-name'
import { ObjectIdOrString, Timestamps } from './database'

export enum NotificationType {
  Added = 'added',
  Updated = 'updated',
  Deleted = 'deleted',
  Renamed = 'renamed',
}

export type Notification<T = ObjectIdOrString> = {
  _id: T
  coll: ColName
  user: T
  type: NotificationType
  author: T
  node?: T
  board?: T
  org?: T
  actorName: string
  subjectName: string
  subjectNameBefore?: string
  diff?: Record<string, unknown>[]
} & Timestamps
