import { Point } from '@/client/utils/point'
import { NodeType } from '@/common/constants/boards'
import { MimeType } from '@/common/constants/image'
import {
  BoardOperations,
  useBoardOperations,
} from '@components/boards/hooks/use-board-operations'
import { useBoardState } from '@components/boards/hooks/use-board-state'
import { useFileDrop } from '@components/boards/hooks/use-file-drop'
import { VirtualBounds } from '@components/boards/hooks/use-virtual-bounds'
import { isUrl } from '@components/boards/utils/is-url'
import { processLink } from '@components/boards/utils/links'
import { getNodeDimensions } from '@components/boards/utils/nodes'
import { pasteImage } from '@components/boards/utils/paste/paste-image'
import { pastePdf } from '@components/boards/utils/paste/paste-pdf'
import { pasteWav } from '@components/boards/utils/paste/paste-wav'
import {
  fromVirtualX,
  fromVirtualY,
} from '@components/boards/utils/virtualization'
import { useClient } from '@helenejs/react'
import React, { useEffect, useRef } from 'react'

async function pasteText({
  xRef,
  yRef,
  text,
  bounds,
  operations,
}: {
  xRef: React.MutableRefObject<number>
  yRef: React.MutableRefObject<number>
  text: string
  bounds: VirtualBounds
  operations: BoardOperations
}) {
  const dimensions = getNodeDimensions({}, text)

  await operations.addNode(
    fromVirtualX(xRef.current, bounds),
    fromVirtualY(yRef.current, bounds),
    {
      type: NodeType.Text,
      name: text,
      ...dimensions,
    },
  )
}

export function usePaste(
  bounds: VirtualBounds,
  viewport: Point,
  boardId: string,
) {
  const client = useClient()
  const { isReadOnly, zoomMultiplier } = useBoardState()

  const disabledRef = useRef(false)

  const viewportRef = useRef(viewport)
  const zoomMultiplierRef = useRef(zoomMultiplier)

  useEffect(() => {
    viewportRef.current = viewport
    zoomMultiplierRef.current = zoomMultiplier
  }, [viewport, zoomMultiplier])

  const xRef = useRef(0)
  const yRef = useRef(0)
  const clientXRef = useRef(0)
  const clientYRef = useRef(0)

  const operations = useBoardOperations()

  useFileDrop(
    (files, point) => {
      // Make sure the position to be dropped is also updated on drop
      xRef.current = point.x / zoomMultiplier - viewportRef.current.x
      yRef.current = point.y / zoomMultiplier - viewportRef.current.y

      const file = files.find(item =>
        [
          MimeType.IMAGE_PNG,
          MimeType.IMAGE_JPEG,
          MimeType.APPLICATION_PDF,
          MimeType.AUDIO_WAV,
        ].includes(item.type),
      )

      if (!file) {
        console.log('Unknown File Type: ', file)
        return
      }

      if (file.type === MimeType.AUDIO_WAV) {
        pasteWav({
          client,
          boardId,
          xRef,
          yRef,
          file,
          bounds,
          operations,
        }).catch(console.error)
        return
      }

      if (file.type === MimeType.APPLICATION_PDF) {
        pastePdf({
          client,
          boardId,
          xRef,
          yRef,
          file,
          bounds,
          operations,
        }).catch(console.error)
        return
      }

      pasteImage({
        client,
        boardId,
        xRef,
        yRef,
        file,
        bounds,
        operations,
      }).catch(console.error)
    },
    [bounds, boardId, operations],
  )

  useEffect(() => {
    console.log('registering paste listener')

    const pasteListener = async (e: ClipboardEvent) => {
      if (disabledRef.current) return
      if (isReadOnly) return
      if (!e.clipboardData) return

      const element = document.elementFromPoint(
        clientXRef.current,
        clientYRef.current,
      ) as HTMLElement | null

      console.log('usePaste element', element)

      const activeElement = document.activeElement as HTMLElement | null

      if (
        activeElement?.tagName !== 'BODY' ||
        !element.dataset?.viewportHandle
      ) {
        return
      }

      e.preventDefault()

      const item = Array.from(e.clipboardData.items).find(
        item =>
          item.kind === 'file' &&
          [
            MimeType.IMAGE_PNG,
            MimeType.IMAGE_JPEG,
            MimeType.APPLICATION_PDF,
          ].includes(item.type),
      )

      if (item) {
        const file = item.getAsFile()

        if (file.type === MimeType.APPLICATION_PDF) {
          await pastePdf({
            client,
            boardId,
            xRef,
            yRef,
            file,
            bounds,
            operations,
          })
          return
        }

        await pasteImage({
          client,
          boardId,
          xRef,
          yRef,
          file,
          bounds,
          operations,
        })
        return
      }

      const text = e.clipboardData.getData('text/plain')

      if (isUrl(text)) {
        await processLink({
          client,
          boardId,
          xRef,
          yRef,
          link: text,
          bounds,
          operations,
        })
        return
      }

      if (text) {
        await pasteText({
          xRef,
          yRef,
          text,
          bounds,
          operations,
        })
        return
      }

      console.log('Unknown Paste Type: ', text)
    }

    const mouseMoveListener = (e: MouseEvent) => {
      xRef.current = e.clientX / zoomMultiplier - viewportRef.current.x
      yRef.current = e.clientY / zoomMultiplier - viewportRef.current.y
      clientXRef.current = e.clientX
      clientYRef.current = e.clientY
    }

    document.addEventListener('mousemove', mouseMoveListener)
    document.addEventListener('paste', pasteListener)

    return () => {
      document.removeEventListener('paste', pasteListener)
      document.removeEventListener('mousemove', mouseMoveListener)
    }
  }, [boardId, isReadOnly, bounds, operations])
}
