import { ClientBoardNodeCollection } from '@/client/data'
import { NodeType } from '@/common/constants/boards'
import { NodeImage } from '@components/boards/node-image'
import { NodeAIChat } from '@components/boards/nodes/view/node-ai-chat'
import { NodeContact } from '@components/boards/nodes/view/node-contact'
import { NodeFlashcardsCreator } from '@components/boards/nodes/view/node-flashcards-creator'
import { NodePdfViewer } from '@components/boards/nodes/view/node-pdf-viewer'
import { NodeTextEditor } from '@components/boards/nodes/view/node-text-editor'
import { NodeComments } from '@components/boards/node-comments'
import { unescapeHtml } from '@components/boards/utils/unescape-html'
import { Drawer } from '@components/ui/drawer'
import { Tabs } from '@mantine/core'
import { useFindOne } from '@helenejs/react'
import React from 'react'

function NodeComponent({ node }) {
  switch (node.type) {
    case NodeType.AIChat:
      return <NodeAIChat node={node} />
    case NodeType.Contact:
      return <NodeContact node={node} />
    case NodeType.Flashcards:
      return <NodeFlashcardsCreator node={node} />
    case NodeType.PDF:
      return <NodePdfViewer node={node} />
    default:
      return <NodeTextEditor node={node} />
  }
}

export function NodeDrawer({ isOpen, onClose, id, onFinishedClosing }) {
  const node = useFindOne(ClientBoardNodeCollection, { _id: id })

  return (
    <Drawer
      open={isOpen}
      onClose={onClose}
      title={node?.name ? unescapeHtml(node.name) : null}
      loading={!node}
      onFinishedClosing={onFinishedClosing}
      className='p-0'
    >
      <Tabs defaultValue='content'>
        <Tabs.List>
          <Tabs.Tab value='content'>Content</Tabs.Tab>
          <Tabs.Tab value='comments'>Comments</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value='content' pt='xs'>
          <NodeComponent node={node} />
          <div className='px-4'>
            {node?.textDetections ? <NodeImage node={node} /> : null}
          </div>
        </Tabs.Panel>

        <Tabs.Panel value='comments' pt='xs'>
          <div className='px-4'>
            <NodeComments nodeId={id} />
          </div>
        </Tabs.Panel>
      </Tabs>
    </Drawer>
  )
}
