import { Rect } from '@/client/utils/rect'
import { AIChat } from '@/common/constants/ai'
import { BaseColor } from '@/common/constants/color'
import {
  ObjectIdOrString,
  SoftDelete,
  Timestamps,
} from '@/common/constants/database'
import { Permission } from './permissions'

export enum BoardBackground {
  Grid = 'grid',
  Dotted = 'dotted',
  Plain = 'plain',
}

export type Board<I = ObjectIdOrString> = {
  _id: I
  name: string
  author: I
  org: I
  teams: I[]
  node?: I
  privacy: BoardPrivacy
  nodeCount: number
  shareWith?: Array<I>
  shareInvitations?: Array<string>
  accessList?: Array<{
    user: I
    permission: Permission
  }>
  diskUsage?: number
  thumbnail?: string
  background: BoardBackground
} & Timestamps &
  SoftDelete

export enum DefaultContactField {
  Address = 'address',
  Birthdate = 'birthdate',
  CNPJ = 'cnpj',
  CPF = 'cpf',
  Email = 'email',
  Facebook = 'facebook',
  GitHub = 'github',
  Instagram = 'instagram',
  LinkedIn = 'linkedin',
  Phone = 'phone',
  RG = 'rg',
  Skype = 'skype',
  Telegram = 'telegram',
  Website = 'website',
  WhatsApp = 'whatsapp',
  X = 'X',
  YouTube = 'youtube',
}

export type ContactInfo = {
  field: DefaultContactField | string
  value: string
}[]

export enum FontSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

export type BoardNode<I = ObjectIdOrString> = {
  _id: I

  type: NodeType

  status?: NodeStatus
  order?: number

  name: string
  color?: BaseColor
  content?: string
  image?: I
  imageBlurHash?: string
  pdf?: I
  m4a?: I
  audio?: I
  url?: string
  isYouTubeVideo?: boolean
  isYouTubeEmbed?: boolean
  author: I
  board?: I
  boardTarget?: I
  isMapPin?: boolean
  isLinkedBoard?: boolean

  fontSize?: FontSize

  // Center of the node?
  x: number
  y: number

  width: number
  height: number

  xw?: number
  yh?: number

  chat?: AIChat

  dominantColorInImage?: string
  textDetections?: Record<string, any>[]
  textDetectionsConcatenated?: string

  contactInfo?: ContactInfo

  _renaming?: boolean
  _vectors?: number[]
  _clientId?: string
  _rect?: Rect
  _temporaryUrl?: string

  transcription?: Record<string, any>

  deck?: I
} & SoftDelete

export type BoardNodeComment<I = ObjectIdOrString> = {
  _id: I
  board: I
  node: I
  content: string
  author: I
} & SoftDelete

export type BoardEdge<I = ObjectIdOrString> = {
  _id: I
  board?: I
  source?: I
  target?: I
  direction?: EdgeDirection
} & SoftDelete

export enum EdgeDirection {
  None = 'none',
  Source = 'source',
  Target = 'target',
}

export enum BoardPrivacy {
  Public = 'public',
  Private = 'private',
}

export enum NodeType {
  Text = 'text',
  Image = 'image',
  PDF = 'pdf',
  URL = 'url',
  Voice = 'voice',
  AIChat = 'ai-chat',
  Contact = 'contact',
  Flashcards = 'flashcards',
  Board = 'board',
  Audio = 'audio',
}

export enum NodeStatus {
  Started = 'started',
  Done = 'done',
}

export enum BoardEvent {
  Refresh = 'board:refresh',
  EdgeStart = 'board:edge:start',

  GroupStart = 'board:group:start',
  GroupMove = 'board:group:move',
  GroupEnd = 'board:group:end',

  ClearSelection = 'board:clear:selection',

  NodeResize = 'board:node:resize',

  ChatMessage = 'board:node:chat:message',
  MessageToken = 'board:node:chat:message:token',

  BoardChanged = 'board:changed',
  NodeChanged = 'node:changed',
  EdgeChanged = 'edge:changed',
  CommentChanged = 'comment:changed',

  OpenNode = 'board:open:node',
  CloseNode = 'board:close:node',

  SetViewportPosition = 'board:set:viewport:position',

  StartRenaming = 'board:start:renaming',
  HighlightNode = 'HighlightNode',
}

export enum BoardFilter {
  All = 'all',
  Public = 'public',
  Private = 'private',
  Shared = 'shared',
  External = 'external',
}

export enum BoardWorkspace {
  All = 'ALL',
  Personal = 'PERSONAL',
}
