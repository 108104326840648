import { useData } from '@/client/hooks/use-data'
import { cn } from '@/client/utils/cn'
import { ColName } from '@/common/constants/col-name'
import {
  Notification,
  NotificationType,
} from '@/common/constants/notifications'
import { prettyElapsedTime } from '@/common/time'
import { useLocaleMemo } from '@hooks/use-locale-memo'
import { t } from '@lingui/macro'
import { Skeleton } from '@mantine/core'
import React from 'react'

type Activity = {
  id: string
  type: NotificationType
  person: { name: string; imageUrl?: string }
  comment?: string
  date: string
  dateTime: string
}

export function Feed() {
  const { loading, data } = useData({
    method: 'boards.notifications.list',
    authenticated: true,
    sort: {
      createdAt: -1,
    },
  })

  const operations = useLocaleMemo(() => ({
    [NotificationType.Added]: t`added`,
    [NotificationType.Deleted]: t`deleted`,
    [NotificationType.Updated]: t`updated`,
    [NotificationType.Renamed]: t`renamed`,
  }))

  const entities = useLocaleMemo(() => ({
    [ColName.BoardNodes]: t`node`,
    [ColName.Boards]: t`board`,
  }))

  if (loading)
    return (
      <div className='space-y-4'>
        <Skeleton h={16} />
        <Skeleton h={16} />
        <Skeleton h={16} />
      </div>
    )

  return (
    <>
      <ul role='list' className='space-y-4'>
        {data.map(
          (notification: Notification<string>, activityItemIdx: number) => {
            const activityItem: Activity = {
              id: notification._id,
              type: notification.type,
              person: { name: notification.actorName },
              date: prettyElapsedTime(
                Date.now() - notification.createdAt.getTime(),
              ),
              dateTime: notification.createdAt.toLocaleDateString(),
              comment: notification.subjectName,
            }

            console.log({ activityItem })

            return (
              <li key={activityItem.id} className='relative flex gap-x-4'>
                <div
                  className={cn(
                    activityItemIdx === data.length - 1 ? 'h-6' : '-bottom-6',
                    'absolute left-0 top-0 flex w-6 justify-center',
                  )}
                >
                  <div className='w-px bg-gray-300/30' />
                </div>

                <>
                  <div className='relative flex h-6 w-6 flex-none items-center justify-center'>
                    {activityItem.type === NotificationType.Added ? (
                      <div className='h-1.5 w-1.5 rounded-full bg-green-500 ring-1 ring-gray-300' />
                    ) : activityItem.type === NotificationType.Updated ||
                      activityItem.type === NotificationType.Renamed ? (
                      <div className='h-1.5 w-1.5 rounded-full bg-blue-500 ring-1 ring-gray-300' />
                    ) : (
                      <div className='h-1.5 w-1.5 rounded-full bg-red-500 ring-1 ring-gray-300' />
                    )}
                  </div>
                  <time
                    dateTime={activityItem.dateTime}
                    className='flex-none py-0.5 text-xs/5 text-gray-500'
                  >
                    {activityItem.date}
                  </time>
                  <p className='py-0.5 text-xs/5 text-gray-500'>
                    <span className='font-bold'>
                      {activityItem.person.name}
                    </span>{' '}
                    {operations[activityItem.type]}{' '}
                    {entities[notification.coll]}{' '}
                    <span className='font-bold'>
                      {notification.subjectName}
                    </span>
                    {notification.type === NotificationType.Renamed ? (
                      <>
                        {' '}
                        {t`from`}{' '}
                        <span className='font-bold'>
                          {notification.subjectNameBefore}
                        </span>
                      </>
                    ) : (
                      ''
                    )}
                  </p>
                </>
              </li>
            )
          },
        )}
      </ul>
    </>
  )
}
