import { CommandCenter } from '@components/boards/command-center'
import React from 'react'

export function Boards() {
  return (
    <>
      <CommandCenter />
    </>
  )
}
