import { ClientBoardNodeCommentCollection } from '@/client/data'
import { useBoardId } from '@components/boards/hooks/use-board-id'
import { FunkyButton } from '@components/ui/funky-button'
import { useClient, useFind } from '@helenejs/react'
import { useMetaboardAuth } from '@hooks/use-metaboard-auth'
import { Button, Text, Textarea } from '@mantine/core'
import { IconPencil, IconTrash } from '@tabler/icons-react'
import { ObjectId } from 'bson'
import React, { Fragment, useState } from 'react'

export function NodeComments({ nodeId }) {
  const client = useClient()
  const { userId } = useMetaboardAuth()
  const boardId = useBoardId()
  const [content, setContent] = useState('')
  const [isEditing] = useState(null)
  const [editedComment, setEditedComment] = useState('')

  const comments = useFind(
    ClientBoardNodeCommentCollection,
    {
      board: boardId,
      node: nodeId,
    },
    {
      sort: { createdAt: 1 },
    },
  )

  const handleAddComment = async () => {
    const _content = content.trim()

    if (_content) {
      const _id = new ObjectId().toHexString()

      ClientBoardNodeCommentCollection.insert({
        _id,
        board: boardId,
        node: nodeId,
        content: _content,
        author: userId,
      })

      client
        .call('boards.addNodeComment', {
          _id,
          boardId,
          nodeId,
          comment: {
            content,
          },
        })
        .catch(console.error)

      setContent('')
    }
  }

  const handleKeyDown = e => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault()
      handleAddComment().catch(console.error)
    }
  }

  return (
    <div>
      <h3 className='mb-4 text-xl font-semibold text-white'>Comments</h3>
      <div className='mb-4 space-y-4'>
        {comments.length > 0 ? (
          comments.map(comment => (
            <Fragment key={comment._id}>
              {isEditing === comment.id ? (
                <Textarea
                  value={editedComment}
                  onChange={e => setEditedComment(e.target.value)}
                  minRows={1}
                  autosize
                  radius='md'
                  className='max-w-[50%] flex-grow'
                />
              ) : (
                <Comment comment={comment} />
              )}
            </Fragment>
          ))
        ) : (
          <Text size='sm'>No comments yet.</Text>
        )}
      </div>
      {!isEditing && (
        <div className='flex items-center gap-2'>
          <Textarea
            value={content}
            onChange={e => setContent(e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder='Add a comment'
            minRows={1}
            autosize
            className='w-1/2'
            radius='md'
          />
          <FunkyButton
            onClick={handleAddComment}
            className='rounded-md bg-green-500 px-4 py-2 font-semibold text-white hover:bg-green-600'
          >
            Send
          </FunkyButton>
        </div>
      )}
    </div>
  )
}

function Comment({ comment, isEditing }) {
  const client = useClient()

  return (
    <div className='flex items-center justify-start rounded-md border border-slate-500/50 p-3'>
      <div>
        <div className='mb-2 flex items-center justify-start gap-2 text-sm font-medium'>
          <img
            src='/profile-picture.png'
            alt={comment.authorName}
            className='my-0 h-8 w-8 rounded-full'
          />
          <span>{comment.authorName}</span>
        </div>
        <div className='flex-grow text-sm text-gray-300'>{comment.content}</div>
      </div>
      <ManageCommentSection
        isEditing={isEditing}
        comment={comment}
        client={client}
      />
    </div>
  )
}

function ManageCommentSection({ isEditing, comment }) {
  const client = useClient()
  const { userId } = useMetaboardAuth()

  if (comment.author !== userId) return null

  return (
    <div className='ml-auto flex items-center gap-1'>
      {isEditing === comment._id ? (
        <Button variant='light' color='green' size='xs'>
          Save
        </Button>
      ) : (
        <Button variant='light' color='blue' size='xs'>
          <IconPencil size={16} />
        </Button>
      )}
      <Button
        variant='light'
        color='red'
        size='xs'
        onClick={() => {
          ClientBoardNodeCommentCollection.deleteOne({
            _id: comment._id,
          })
          client
            .call('boards.deleteNodeComment', {
              _id: comment._id,
              boardId: comment.board,
              nodeId: comment.node,
            })
            .catch(console.error)
        }}
      >
        <IconTrash size={16} />
      </Button>
    </div>
  )
}
